import { FC, useMemo } from "react";
import classNames from "classnames";
import { PieceInterface } from "../interfaces";

export enum PieceMenuOptions {
  EDIT,
  DUPLICATE,
  DELETE,
}
interface PieceTableProps {
  pieces: PieceInterface[];
  editMode?: boolean;
  editIndex?: number;
}

const PieceTable: FC<PieceTableProps> = ({ pieces, editMode, editIndex }) => {
  const orderedPieces = useMemo(() => {
    return [...pieces].sort(
      (a, b) => (a.pieceNumber ?? Infinity) - (b.pieceNumber ?? Infinity)
    );
  }, [pieces]);

  return (
    <table className="table-auto w-full mt-4">
      <thead>
        <tr>
          <th className="text-left px-4 py-2 font-semibold text-xs" />
          <th className="sm:block lg:hidden text-left px-4 py-2 font-semibold text-xs">
            VALORES
          </th>
          <th className="hidden lg:block text-left px-4 py-2 font-semibold text-xs">
            PESO
          </th>
          <th className="text-left px-4 py-2 font-semibold text-xs">
            CATEGORÍA
          </th>
          <th className="hidden lg:block text-left px-4 py-2 font-semibold text-xs">
            DIMENSIONES
          </th>
        </tr>
      </thead>
      <tbody>
        {orderedPieces.map((piece, index) => (
          <tr
            key={index}
            className={classNames(
              index % 2 === 0 && "bg-gray-50",
              editMode && editIndex === index && "border-2 border-indigo-600"
            )}
          >
            <td className="px-4 py-4 text-xs text-gray-500 font-semibold">
              {index + 1}
            </td>
            <td className="sm:block lg:hidden px-4 py-4 text-xs text-gray-500 font-semibold">
              {piece.weight.toFixed(2)} Kg
              <br />
              {piece.height.toFixed(0)}cm - {piece.width.toFixed(0)}cm -{" "}
              {piece.length.toFixed(0)}cm
            </td>
            <td className="hidden lg:block px-4 py-4 text-xs text-gray-500 font-semibold">
              {piece.weight.toFixed(2)} Kg
            </td>
            <td className="px-4 py-4 text-xs text-gray-500 font-semibold">
              {piece.category.name}
            </td>
            <td className="hidden lg:block px-4 py-4 text-xs text-gray-500 font-semibold">
              {piece.height.toFixed(0)}cm - {piece.width.toFixed(0)}cm -{" "}
              {piece.length.toFixed(0)}cm
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default PieceTable;
