import { FC, useMemo } from "react";
import { FormSelect } from "./FormFields";
import { ItemInterface } from "../interfaces";
import { TrashIcon } from "@heroicons/react/20/solid";
import {
  currencyExchangeText,
  formatAmount,
  useCurrencyExchanges,
} from "../utils";
import classNames from "classnames";

interface ShipmentItemTableProps {
  items: ItemInterface[];
  total?: number;
  distance?: number;
  declaredValue?: number;
  ipostel?: number;
  availableItems?: ItemInterface[];
  editable?: boolean;
  hideSelect?: boolean;
  showTaxes?: boolean;
  setItems?: (items: ItemInterface[]) => void;
}
const ShipmentItemTable: FC<ShipmentItemTableProps> = ({
  items,
  total,
  distance,
  declaredValue,
  ipostel = 0,
  availableItems = [],
  editable = false,
  hideSelect = false,
  showTaxes = false,
  setItems = () => {},
}) => {
  const exchanges = useCurrencyExchanges();

  const deleteItem = (item: ItemInterface) => {
    const newItems = [...items];
    newItems.splice(newItems.indexOf(item), 1);
    setItems(newItems);
  };

  const mandatoryItems = useMemo(() => {
    return items.filter((item) => item.mandatory);
  }, [items]);

  const optionalItems = useMemo(() => {
    return items.filter((item) => !item.mandatory);
  }, [items]);

  const absoluteTotal = useMemo(() => {
    return items
      .filter((item) => !item.rate.isPercentage)
      .reduce((acc, item) => acc + item.rate.value, 0);
  }, [items]);

  const totalToShow = useMemo(() => {
    if (total !== undefined) return total;

    const percentageTotal = items
      .filter((item) => item.rate.isPercentage)
      .reduce((acc, item) => acc + item.rate.value, 0);

    return absoluteTotal * (1 + percentageTotal / 100);
  }, [items, absoluteTotal, total]);

  const iva = useMemo(() => {
    return +items.reduce((acc, item) => acc + item.rate.iva, 0).toFixed(2);
  }, [items]);

  const totalWithTaxes = useMemo(() => {
    return totalToShow + iva + ipostel;
  }, [totalToShow, iva, ipostel]);

  return (
    <table className="table-auto w-full mt-4">
      <thead>
        <tr>
          <th className="text-left px-4 py-2 font-semibold text-xs">
            SERVICIO
          </th>
          <th className="hidden lg:block text-left px-4 py-2 font-semibold text-xs">
            DETALLES
          </th>
          <th className="text-right px-4 py-2 font-semibold text-xs">COSTO</th>
        </tr>
      </thead>
      <tbody>
        {mandatoryItems.map((item, i) => {
          const cost = item.rate.isPercentage
            ? (item.rate.value * absoluteTotal) / 100
            : item.rate.value;

          return (
            <tr
              key={i}
              className={classNames("w-full", `${i % 2 === 0 && "bg-gray-50"}`)}
            >
              <td className="text-xs px-4 py-4 font-semibold">
                {item.name}
                <p
                  className={classNames(
                    "lg:hidden",
                    "text-xs font-semibold text-gray-600",
                    !item.name.includes("Flete") && "hidden"
                  )}
                >
                  Zona Tarifaria: {item.rate.tierName}
                </p>
                <p
                  className={classNames(
                    "lg:hidden",
                    "text-xs font-semibold text-gray-600",
                    (distance === undefined || item.id !== "21") && "hidden"
                  )}
                >
                  Distancia: {distance?.toFixed(2)} km
                </p>
                <p
                  className={classNames(
                    "lg:hidden",
                    "text-xs font-semibold text-gray-600",
                    (declaredValue === 0 ||
                      declaredValue === undefined ||
                      item.id !== "20") &&
                      "hidden"
                  )}
                >
                  Valor declarado: {formatAmount(declaredValue ?? 0)} $
                </p>
              </td>

              <td className="hidden lg:block text-xs px-4 py-4 font-semibold text-gray-600">
                <p
                  className={classNames(
                    !item.name.includes("Flete") && "hidden"
                  )}
                >
                  Zona Tarifaria: {item.rate.tierName}
                </p>

                <p
                  className={classNames(
                    (distance === undefined || item.id !== "21") && "hidden"
                  )}
                >
                  Distancia: {distance?.toFixed(2)} km
                </p>
                <p
                  className={classNames(
                    (declaredValue === 0 ||
                      declaredValue === undefined ||
                      item.id !== "20") &&
                      "hidden"
                  )}
                >
                  Valor declarado: {formatAmount(declaredValue ?? 0)} $
                </p>
              </td>
              <td className="text-right text-xs px-4 py-4 font-semibold">
                <div className="flex flex-col items-end">
                  <p className="text-xs font-semibold text-gray-600">
                    {currencyExchangeText(cost, exchanges, "USD")}
                  </p>
                  <p className="text-xs text-gray-400 ">
                    {currencyExchangeText(cost, exchanges, "BS")}
                  </p>
                </div>
              </td>
            </tr>
          );
        })}

        {optionalItems.map((item, i) => {
          const cost = item.rate.isPercentage
            ? (item.rate.value * absoluteTotal) / 100
            : item.rate.value;

          return (
            <tr
              key={i}
              className={`${
                (mandatoryItems.length + i) % 2 === 0 && "bg-gray-50"
              }`}
            >
              <td className="text-xs px-4 py-4 font-semibold">{item.name}</td>
              <td className="text-xs px-4 py-4 font-semibold"></td>
              <td className="text-right text-xs px-4 py-4 font-semibold">
                <div className="flex flex-col items-end">
                  <p className="text-xs font-semibold text-gray-600">
                    {currencyExchangeText(cost, exchanges, "USD")}
                  </p>
                  <p className="text-xs text-gray-400 ">
                    {currencyExchangeText(cost, exchanges, "BS")}
                  </p>
                </div>
              </td>
              {editable && (
                <td className="justify-center items-center">
                  <div
                    onClick={() => deleteItem(item)}
                    className="rounded-full bg-red-100 hover:bg-red-200 hover:cursor-pointer p-2"
                    style={{ width: "fit-content " }}
                  >
                    <TrashIcon
                      className="h-5 w-5 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                </td>
              )}
            </tr>
          );
        })}

        <tr
          className={`${
            (mandatoryItems.length + optionalItems.length) % 2 === 0 &&
            "bg-gray-50"
          }`}
        >
          <td className="text-xs px-4 py-4 font-semibold">SUBTOTAL GUIA</td>
          <td className="hidden lg:block"></td>
          <td className="text-right text-xs px-4 py-4 font-semibold ">
            <div className="flex flex-col items-end">
              <p className="text-xs font-semibold text-gray-600">
                {currencyExchangeText(totalToShow, exchanges, "USD")}
              </p>
              <p className="text-xs text-gray-400 ">
                {currencyExchangeText(totalToShow, exchanges, "BS")}
              </p>
            </div>
          </td>

          {editable && <td className="justify-center items-center" />}
        </tr>

        <tr
          className={classNames(
            (mandatoryItems.length + optionalItems.length) % 2 === 1 &&
              "bg-gray-50",
            !showTaxes && "hidden",
            "border-t-2 border-gray-400"
          )}
        >
          <td className="text-xs px-4 py-4 font-semibold ">IVA (16%)</td>
          <td className="hidden lg:block"></td>
          <td className="text-right text-xs px-4 py-4 font-semibold ">
            <div className="flex flex-col items-end">
              <p className="text-xs font-semibold text-gray-600">
                {currencyExchangeText(iva, exchanges, "USD")}
              </p>
              <p className="text-xs text-gray-400 ">
                {currencyExchangeText(iva, exchanges, "BS")}
              </p>
            </div>
          </td>
        </tr>

        <tr
          className={classNames(
            (mandatoryItems.length + optionalItems.length) % 2 === 0 &&
              "bg-gray-50",
            !showTaxes && "hidden"
          )}
        >
          <td className="text-xs px-4 py-4 font-semibold ">Franqueo Postal</td>
          <td className="hidden lg:block"></td>
          <td className="text-right text-xs px-4 py-4 font-semibold ">
            <div className="flex flex-col items-end">
              <p className="text-xs font-semibold text-gray-600">
                {currencyExchangeText(ipostel, exchanges, "USD")}
              </p>
              <p className="text-xs text-gray-400 ">
                {currencyExchangeText(ipostel, exchanges, "BS")}
              </p>
            </div>
          </td>
        </tr>

        <tr
          className={classNames(
            (mandatoryItems.length + optionalItems.length) % 2 === 1 &&
              "bg-gray-50",
            !showTaxes && "hidden"
          )}
        >
          <td className="text-xs px-4 py-4 font-semibold ">
            TOTAL
          </td>
          <td className="hidden lg:block"></td>
          <td className="text-right text-xs px-4 py-4 font-semibold">
            <div className="flex flex-col items-end">
              <p className="text-xs font-semibold text-gray-600">
                {currencyExchangeText(totalWithTaxes, exchanges, "USD")}
              </p>
              <p className="text-xs text-gray-400 ">
                {currencyExchangeText(totalWithTaxes, exchanges, "BS")}
              </p>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default ShipmentItemTable;
